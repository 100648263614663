import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput, ScrollView, } from 'react-native'
import React, {useState, useEffect} from 'react'
import { useNavigation, useRoute } from '@react-navigation/native';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations';
import { getShopRequest } from '../../graphql/queries';
const {width, height} = Dimensions.get('window')
const TopActivity = () => {
 
    const navigation = useNavigation();
    const route = useRoute().params

    const {orderDet} = route

    const mainColour = '#32a852';

    const [driverSelect, setDriverSelect] = useState([])
    const [OrderProduct, setOrderProduct] = useState([])
    const [reqDet, setRetDet] = useState([])
    const [driver, setDriver] = useState([])

    const date = new Date();


     async function fetchUsers() {

        const allOrders = await API.graphql({ query: queries.listOrderProducts, variables:
           { filter : {
             orderID: {eq: orderDet?.id},
           },

           }});

        const todos = allOrders.data.listOrderProducts.items
        setOrderProduct(todos)

        const gettingUser = await API.graphql(graphqlOperation(getShopRequest, { id: orderDet?.id}))
        const gettingUserData = gettingUser.data.getShopRequest

        setRetDet(gettingUserData)



        const allDrivers = await API.graphql({ query: queries.listDriverLists});

        const todot = allDrivers.data.listDriverLists.items
        setDriver(todot)

     }

     useEffect(() => {
      fetchUsers()

    }, [])


    const renderList = ({item})=>{
        return(
            <View style={{marginRight: 10}}>
                <View>
                    <Image style={{width: 80, height: 80, borderRadius: 5}} source={{uri: item?.image}} />
                </View>
                <View>
                    <Text>{item?.title}</Text>
                    <Text>Qty : {item?.quantity}</Text>
                    <Text>R{Number(item?.price).toFixed(2)}</Text>
                </View>
            </View>
        )
    }

    const renderDrivers = ({item}) => {
        return(
            <View style={{marginRight: 15}}>
                <TouchableOpacity onPress={()=> setDriverSelect(item)} style={{ width: '100%'}}>
                    <Image style={{height: 80, width: 80, borderRadius: 10, borderWidth: 3, borderColor: item?.id == driverSelect?.id ? mainColour : 'white'}} source={{uri: item?.image}} />
                    <Text style={{fontSize: 10}}>{item?.name} {item?.surname}</Text>
                </TouchableOpacity>

            </View>
        )
    }

    const acceptOrder = async() =>{

        if(driverSelect === null){
            return
        }


        const AcceptInput = {
              id: orderDet?.id,
              busName: orderDet?.busName,
              name: orderDet?.busName,
              website: orderDet?.website,
              busReg: orderDet?.busReg,
              social: orderDet?.social,
              category: orderDet?.category,
              ownerName: orderDet?.ownerName,
              ownerSurname: orderDet?.ownerSurname,
              ownerEmail: orderDet?.ownerEmail,
              carryStock: orderDet?.carryStock,
              physStore: orderDet?.physStore,
              balance: 0.00
            }

            const UserUpdtInput = {
              id: orderDet?.id,
              store: true,
                         
            }

            const deleteInput = {
              id: orderDet?.id,                         
            }

        await API.graphql({query: mutations.createShop, variables: {input: AcceptInput}}).then((e)=> 
        {
            API.graphql({query: mutations.updateUserInfo, variables: {input: UserUpdtInput}}).then((e)=> {
                API.graphql({query: mutations.deleteShopRequest, variables: {input: deleteInput}}).then((e=> {
                    navigation.goBack()
                })) 
                
            })
            
        }
        
        )
    }



    const declineOrder = async() =>{
        const declinedInput = {
              id: orderDet?.id,
              statusC: 'Declined',
              statusB: 'Cancelled',            
            }

            const CancelledNotificationInput = {
              title: "Order Declined",
              message: 'Your order number :' + orderDet?.id + ' has been Declined.',
              backgroundColor: mainColour,
              letter: 'O',
              author: "First Team",
              read: false,
              date: date.getDate,
              time: date.getTime,
              userSub: orderDet?.userSub
              
            }

        await API.graphql({query: mutations.updateOrder, variables: {input: declinedInput}}).then((e)=> 
        {
            API.graphql({query: mutations.createNotificationsList, variables: {input: CancelledNotificationInput}}).then((e)=> {
                navigation.goBack()
            })
            
        }
        )
    }
    

  return (
    <View style={{flex: 1, backgroundColor: '#ffffff'}}>
        <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>

            <View style={{marginTop: 15, flexDirection: 'row'}}>

                <View style={{width: '33%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                    <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Order Details</Text>
                    </View>

                    <ScrollView style={{}}>

                        <View style={{width: '100%', padding: 15}}>

                                <View style={{flexDirection: 'row'}}>
                                    <Text style={{width: 150}}>Company</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{fontWeight: 'bold'}}>{orderDet?.busName || `unAssigned`}</Text>
                                </View>


                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>UserID</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{ width: 150}}>{orderDet?.id.slice(0,10)}...</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Date</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.createdAt}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Business Reg #</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.busReg}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Website</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{width: 200}}>{orderDet?.website}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Social Media</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.social}</Text>
                                </View>

                        </View>

                      

                    </ScrollView>


                </View>

                <View style={{width: '33%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                    <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>..more</Text>
                    </View>

                    <View style={{marginTop: 0}}>

                       <View style={{width: '100%', padding: 15}}>

                                <View style={{flexDirection: 'row'}}>
                                    <Text style={{width: 150}}>Owner Name</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{fontWeight: 'bold'}}>{orderDet?.ownerName || `unAssigned`}</Text>
                                </View>


                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Surname</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{ width: 150}}>{orderDet?.ownerSurname}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Email</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.ownerEmail}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Physical Store</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.physStore ? `Yes` : `No`}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Supply Retail Outlets</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{width: 200}}>{orderDet?.supplyRet ? `Yes` : `No`}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Brands they carry</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.brands}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Unique Products</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.unique}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Comments</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.comments}</Text>
                                </View>

                        </View>                       
                    </View>

                    




                </View>








                <View style={{width: '33%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                    <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Options</Text>
                    </View>
                     <TouchableOpacity onPress={()=> acceptOrder()} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: 'green', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <MaterialCommunityIcons  name="check" size={20} color="white" />
                </View>
                
                <Text>Accept Request</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> declineOrder() } style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: 'red', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="cross" size={20} color="white" />
                </View>
                
                <Text>Decline Request</Text>
                    </TouchableOpacity>
                </View>
            </View>



        </View>
    </View>
  )
}

export default TopActivity