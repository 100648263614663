import { View, Text, ActivityIndicator, ImageBackground, TouchableOpacity } from 'react-native'
import React, {useState, useEffect} from 'react'
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { useNavigation } from '@react-navigation/native';
const HomeScreen = () => {

    
    const [users, setUsers] = useState([])
    const [orders, setOrders] = useState([])
    const [products, setProducts] = useState([])
    // const [driverList, setDriverList] = useState([])

    const navigation = useNavigation()

    async function fetchUsers() {

        const allTodos = await API.graphql({ query: queries.listUserInfos});

        const todos = allTodos.data.listUserInfos.items
        setUsers(todos)

        const allProd = await API.graphql({ query: queries.listProducts});

        const prods = allProd.data.listProducts.items
        setProducts(prods)


        // orders

        const allTodosInfo = await API.graphql({ query: queries.listOrders, variables:
           { filter : {
             statusC: {eq: 'Pending'},
             statusB: {eq: 'Ongoing'}
           }

           }});
        const todosInfo = allTodosInfo.data.listOrders.items

        setOrders(todosInfo)


        console.log('items :', todos)


        // Drivers

        // const allDrivers = await API.graphql({ query: queries.listDriverLists});

        // const todosD = allDrivers.data.listDriverLists.items
        // setDriverList(todosD)

     }

     useEffect(() => {
      fetchUsers()

    }, [])


  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      {/* <Text>ss {users.length()}</Text> */}


      <View style={{flexDirection: 'row'}}>
         <View style={{ width: '60%', borderRightWidth: 1, height: '100%', borderColor: '#d1d1d1'}}>

        <View style={{flexDirection: 'row', margin: 20, height: '100%'}}>
          <View >
            <ImageBackground style={{ alignItems: 'center', justifyContent: 'center', width: 200, height: 100, borderRadius: 20}} imageStyle={{borderRadius:20, }} source={{uri: 'https://images.unsplash.com/photo-1557683304-673a23048d34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=282&q=80'}}>
              <View>
                <Text style={{fontSize: 20, color: 'white', fontWeight: '900'}}>{users.length}</Text>
                <Text style={{fontSize: 20, color: 'white', fontWeight: '900'}}>Users</Text>
              </View>
            </ImageBackground>
          </View>

          <View style={{marginLeft: 30}}>
            <ImageBackground style={{ alignItems: 'center', justifyContent: 'center', width: 200, height: 100, borderRadius: 20}} imageStyle={{borderRadius:20, }} source={{uri: 'https://images.unsplash.com/photo-1557682224-5b8590cd9ec5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=829&q=80'}}>
              <View>
                <Text style={{fontSize: 20, color: 'white', fontWeight: '900'}}>{orders.length}</Text>
                <Text style={{fontSize: 20, color: 'white', fontWeight: '900'}}>Pending Orders</Text>
              </View>
            </ImageBackground>
          </View>


          <View style={{marginLeft: 30}}>
            <ImageBackground style={{ alignItems: 'center', justifyContent: 'center', width: 200, height: 100, borderRadius: 20}} imageStyle={{borderRadius:20, }} source={{uri: 'https://images.unsplash.com/photo-1604079681864-c6fbd7eb109c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'}}>
              <View>
                <Text style={{fontSize: 20, color: 'white', fontWeight: '900'}}>{products.length}</Text>
                <Text style={{fontSize: 20, color: 'white', fontWeight: '900'}}>Products</Text>
              </View>
            </ImageBackground>
          </View>
        </View>

          {/* <View style={{marginLeft: 20, marginTop: -50}}>
              <ImageBackground style={{ alignItems: 'center', justifyContent: 'center', width: 200, height: 100, borderRadius: 20}} imageStyle={{borderRadius:20, }} source={{uri: 'https://images.unsplash.com/photo-1523821741446-edb2b68bb7a0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'}}>
                <View>
                  <Text style={{fontSize: 20, color: 'white', fontWeight: '900'}}>{setDriverList.length}</Text>
                  <Text style={{fontSize: 20, color: 'white', fontWeight: '900'}}>Driver(s)</Text>
                </View>
              </ImageBackground>
          </View> */}
        </View>

        <View style={{width: '40%', height: '100%'}}>

            <View>
               <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Options</Text>
                    </View>
            </View>

            <TouchableOpacity onPress={()=> {}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: '#706b6b', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <MaterialCommunityIcons  name="arrow-bottom-right-bold-box" size={20} color="white" />
                </View>
                
                <Text>Discounts</Text>
            </TouchableOpacity>
          
            <TouchableOpacity onPress={()=> {navigation.navigate('Banners')}} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: '#706b6b', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <MaterialCommunityIcons  name="arrow-bottom-right-bold-box" size={20} color="white" />
                </View>
                
                <Text>Banners</Text>
            </TouchableOpacity>
        </View>
      </View>
      
    </View>
  )
}

export default HomeScreen