import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onAddedOrder, onUpdatedOrder, onDeletedOrder } from '../../graphql/subscriptions';


const {width, height} = Dimensions.get('window')
const UserDetails = () => {

    const route = useRoute().params

    const {userDet} = route
    

    const mainColour = '#32a852'

    const navigation = useNavigation();

    const [showMessage, setShowMessage] = useState(false);
    const [orderList, setOrderList] = useState([])
    const [OrderUpdt, setOrderUpdt] = useState([])

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const date = new Date();

    const orderListb = [
        {id: 1,
        orderAmount: 500,
        paymentStatus: 'Cash',
        deliveryStatus: 'Requested',
        address: '45 Georgia Crecent',
        lat: '',
        lng: '',
        type: 'Cart',
        amount: 200,
        qty: 2,
        createdAt: '22 May 2022'
        },
        {id: 2,
        orderAmount: 300,
        paymentStatus: 'Cash',
        deliveryStatus: 'Requested',
        address: '45 Georgia Crecent',
        lat: '',
        lng: '',
        type: 'Emergency',
        amount: 200,
        qty: 2,
        createdAt: '3 June 2022'
        }
    ]

    async function fetchUsers() {

        const allOrders = await API.graphql({ query: queries.listOrders, variables:
           { filter : {
             statusC: {eq: 'Pending'},
             userSub: {eq: userDet?.id},
             statusB: {eq: 'Ongoing'},
           },

           }});

        const todos = allOrders.data.listOrders.items
        setOrderList(todos)


        console.log('items :', todos)

     }

     useEffect(() => {
      fetchUsers()

    }, [OrderUpdt])



    let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onUpdatedOrder)).subscribe( {next: (daraa) => {
         setOrderUpdt(daraa)
     }, })
     
     subsUpdateDel = API.graphql(graphqlOperation(onDeletedOrder)).subscribe( {next: (daraaa) => {
         setOrderUpdt(daraaa)
     }, }) 

     subsUpdateCreate = API.graphql(graphqlOperation(onAddedOrder)).subscribe( {next: (daraaaaa) => {
         setOrderUpdt(daraaaaa)
     }, }) 

    }

    useEffect(() =>{
        setUpSus();

        return() =>{
            subsUpdate.unsubscribe();
            subsUpdateDel.unsubscribe();
            subsUpdateCreate.unsubscribe();
        };

    },[]);


    const renderList =({item})=>{
        return(
            <TouchableOpacity onPress={()=> navigation.navigate('TopActivity', {orderDet: item})} style={{height: 59, width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View style={{marginLeft: 30, width: 250}}>
                        <View style={{flexDirection: 'row', height: 30}}>

                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <Entypo name='shopping-cart' size={10} color="black" />
                                <Text style={{marginLeft: 10, width: 80,}}>{item?.type}</Text>  
                                
                            </View>
                          
                            <View style={{flexDirection: 'row', marginLeft: 15, alignItems: 'center'}}>
                                {/* calendar */}
                                <View>
                                   <Entypo name="calendar" size={10} color="black" />
                                </View>

                                {/* date */}
                                <View style={{marginLeft: 10}}>
                                    <Text>{item?.createdAt.slice(0,10)}</Text>
                                </View>
                            </View>
                        </View>
                        
                        <Text>R{Number(item?.orderAmount).toFixed(2)}</Text>
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <TouchableOpacity onPress={()=> console.warn('cancelled')} style={{width: 20, height: 20, borderRadius: 50, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center'}}>
                            <Entypo name="cross" size={12} color="white" />
                        </TouchableOpacity>

                        {/* <View style={{width: 25, height: 25, marginLeft: 20, borderRadius: 50, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                            <Entypo name="check" size={10} color="white" />
                        </View> */}
                    </View>

                    <View style={{marginRight: 30}}>
                        <Ionicons name="md-arrow-forward-circle-sharp" size={24} color="black" />
                    </View>
            </TouchableOpacity>
        )
    }

    const itemSeperator = ()=> {
        return(
            <View style={{height: 1, backgroundColor: '#d1d1d1'}} />
        )
    }

    const sendMessage = async()=> {

          const inputMessage = {
              title: title,
              message: message,
              backgroundColor: mainColour,
              letter: 'A',
              author: "First Team",
              read: false,
              date: date.getDate,
              time: date.getTime,
              userSub: userDet?.id
              
            }

        await API.graphql({query: mutations.createMessageList, variables: {input: inputMessage}}).then((e)=> setShowMessage(false))

    }


    if(showMessage === true){
        return(
            <View style={{backgroundColor: 'white', flex: 1}}>
                <View style={{margin: 15}}>
                    <TouchableOpacity onPress={()=> setShowMessage(false)}>
                        <Entypo name="cross" size={24} color="black" />
                    </TouchableOpacity>

                    <View style={{alignSelf: 'center', marginTop: 30}}>

                        {/* title */}
                        <View>
                            <Text style={{marginBottom: 5}}>Title</Text>
                            <TextInput value= {title} onChangeText={setTitle} style={{height: 45, width: 300, paddingLeft: 15, borderWidth: 1, borderColor: '#d1d1d1', borderRadius: 10}}/>
                        </View>

                        {/* message */}
                        <View style={{width: 300, marginTop: 15}}>
                        <Text style={{marginBottom: 5}}>Message</Text>
                            <TextInput value= {message} onChangeText={setMessage} style={{height: 200, width: 300, paddingLeft: 15, borderWidth: 1, borderColor: '#d1d1d1', borderRadius: 10}}/>

                        </View>

                        <View style={{marginTop: 15}}>
                            <TouchableOpacity onPress={()=> {sendMessage(), setShowMessage(false)}} style={{height: 40, width: 80, borderRadius: 10, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{color: 'white'}}>Send</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        )
    }


    if(width < 799 || width === 799 ){
        return(
           
                <View style={{margin: 15, backgroundColor: 'white', borderRadius: 10}}>
   
        <TouchableOpacity style={{marginLeft: 10, marginBottom: 5, marginTop: 10}} onPress={()=> navigation.goBack()}>
            <Ionicons name="arrow-back-outline" size={24} color="black" />
        </TouchableOpacity>

        <View style={{alignSelf: 'center'}}>

        <View style={{marginTop: 30, flexDirection: 'row'}}>

            <View style={{width: 100, alignItems: 'center'}}>
                <Image style={{width: 80, height: 80}} source={require( '../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')} />
            </View>

            <View style={{marginLeft: 15}}>


            
                <View style={{width: 200, marginTop: 10}}>
                    <Text style={{fontSize: 15, fontWeight: 'bold'}}>{userDet?.name} {userDet?.surname}</Text>
                </View>
                <View style={{width: 200, alignItems: 'center', marginTop: 10, flexDirection: 'row'}}>
                    <MaterialCommunityIcons name="email-sync" size={15} color="black" />
                    <Text style={{fontSize: 12, marginLeft: 10}}>{userDet?.email}</Text>
                </View>

                <View style={{width: 200, alignItems: 'center', marginTop: 10, flexDirection: 'row'}}>
                    <Ionicons name="phone-portrait-sharp" size={15} color="black" />
                    <Text style={{fontSize: 12, marginLeft: 10}}>{userDet?.phoneNumber}</Text>
                </View>

                <View style={{width: 200, alignItems: 'center', marginTop: 10, flexDirection: 'row'}}>
                    <Octicons name="diff-added" size={15} color="black" />
                    <Text style={{fontSize: 12, marginLeft: 10}}>{userDet?.createdAt.slice(0, 10)}</Text>
                </View>

                <TouchableOpacity onPress={()=> setShowMessage(true)} style={{width: 100, height: 40, borderRadius: 10, marginTop: 15, backgroundColor: '#FF4F00', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 30}}>
                    <AntDesign name="message1" size={15} color="white" />
                    <Text style={{color: 'white', fontWeight: '400', marginLeft: 10, fontSize: 13}}>Message</Text>
                </TouchableOpacity>

            </View>
        </View>

        </View>
            </View>
        )
    }

  return (
    <View style={{flex: 1, backgroundColor: '#ffffff'}}>

        <View style={{marginTop: 15}}>
   
        <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
            <Ionicons name="arrow-back-outline" size={24} color="black" />
        </TouchableOpacity>

        <View style={{marginTop: 15, flexDirection: 'row'}}>

        <View style={{width: '33%', paddingLeft: 15, paddingTop: 30, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>

            <View style={{width: 200, alignItems: 'center'}}>
                <Image style={{width: 100, height: 100}} source={require( '../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')} />
            </View>
            <View style={{width: 200, alignItems: 'center', marginTop: 10}}>
                <Text style={{fontSize: 20, fontWeight: 'bold'}}>{userDet?.name} {userDet?.surname}</Text>
            </View>
            <View style={{width: 200, alignItems: 'center', marginTop: 10, flexDirection: 'row'}}>
                <MaterialCommunityIcons name="email-sync" size={20} color="black" />
                <Text style={{fontSize: 15, marginLeft: 10}}>{userDet?.email}</Text>
            </View>

            <View style={{width: 200, alignItems: 'center', marginTop: 10, flexDirection: 'row'}}>
                <Ionicons name="phone-portrait-sharp" size={20} color="black" />
                <Text style={{fontSize: 15, marginLeft: 10}}>{userDet?.phoneNumber}</Text>
            </View>

            <View style={{width: 200, alignItems: 'center', marginTop: 10, flexDirection: 'row'}}>
                <Octicons name="diff-added" size={20} color="black" />
                <Text style={{fontSize: 15, marginLeft: 10}}>{userDet?.createdAt.slice(0, 10)}</Text>
            </View>

            <TouchableOpacity onPress={()=> setShowMessage(true)} style={{width: 120, height: 45, borderRadius: 10, marginTop: 15, backgroundColor: '#FF4F00', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                <AntDesign name="message1" size={15} color="white" />
                <Text style={{color: 'white', fontWeight: '400', marginLeft: 10}}>Message</Text>
            </TouchableOpacity>

        </View>

        <View style={{width: '33%', borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1'}}>

             <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>Top Activity</Text>
            </View>

            <View>
                <FlatList 
                    data={orderList}
                    keyExtractor={(item, index) => index.toString()}
                    renderItem={renderList}
                    ItemSeparatorComponent={itemSeperator}
                    style={{height: 350}}
                />
            </View>


        </View>

        <View style={{width: '33%', borderRightWidth: 0, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1'}}>

            <View style={{alignSelf: 'center', marginBottom: 0, height: 60, justifyContent: 'center'}}>
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>Options</Text>
            </View>

            <TouchableOpacity style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 1, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40}}>
                    <MaterialCommunityIcons name="history" size={20} color="black" />
                </View>
                
                <Text>Order History</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40}}>
                    <MaterialCommunityIcons  name="sale" size={20} color="black" />
                </View>
                
                <Text>Assign Discount</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: 'red', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <MaterialIcons  name="cancel" size={15} color="white" />
                </View>
                
                <Text>Suspend User</Text>
            </TouchableOpacity>

        </View>


        </View>

        </View>
    </View>
  )
}

export default UserDetails