import { View, Text, FlatList, TouchableOpacity, Image, TextInput, ImageBackground } from 'react-native'
import React, {useState, useEffect}  from 'react'
import { Entypo } from '@expo/vector-icons'; 
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'; 
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { useNavigation } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { onAddedShopRequest, onUpdatedShopRequest, onDeletedShopRequest } from '../../graphql/subscriptions';
import { onShopAdded, onShopUpdated, onShopDeleted } from '../../graphql/subscriptions';
const StoreScreen = () => {

    const navigation = useNavigation()

    const [ReqUpdt, setReqUpdt] = useState([])
    const [orderList, setOrderList] = useState([])

    const [shopList, setShopList] = useState([])
    const [orderActive, setOrderActive] = useState([])

    const [reqList, setReqList] = useState([])

    async function fetchUsers() {

        const allOrders = await API.graphql({ query: queries.listShopRequests});
        const todos = allOrders.data.listShopRequests.items
        setReqList(todos)

        // active orders only

        // const activeOrders = await API.graphql({ query: queries.listOrders, variables:
        //    { 
        //     filter : {   
        //        and: [{  statusB: {eq: 'Ongoing'}},
        //                 {statusC:{ ne: 'Pending'}}]
        // },

        //    }});



        // const todosActive = activeOrders.data.listOrders.items
        // setShopActive(todosActive)


         const allOrdersList = await API.graphql({ query: queries.listShops});

         const todoss = allOrdersList.data.listShops.items

         setShopList(todoss)



     }

     const renderList =({item})=>{
        return(
            <TouchableOpacity onPress={()=> navigation.navigate('TopActivity', {orderDet: item})} style={{height: 80, width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                    
                    
                    <View style={{marginLeft: 30, width: 250}}>

                            <View style={{}}>
                                <Text style={{fontSize: 16, fontWeight: '800'}}>{item?.busName}</Text>
                            </View>
                        <View style={{flexDirection: 'row', height: 30}}>

                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <Entypo name='shopping-cart' size={10} color="black" />
                                <Text style={{marginLeft: 15}}>{item?.category}</Text>
                            </View>
                          
                            <View style={{flexDirection: 'row', marginLeft: 15, alignItems: 'center'}}>
                                {/* calendar */}
                                <View>
                                   <Entypo name="calendar" size={10} color="black" />
                                </View>

                                {/* date */}
                                <View style={{marginLeft: 10}}>
                                    <Text style={{fontSize: 13}}>{item?.createdAt.slice(0,10)}</Text>
                                </View>
                            </View>

                           

                        </View>
                        
                        <Text style={{fontSize: 13}}>{item?.website}</Text>

                         
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <TouchableOpacity onPress={()=> console.warn('cancelled')} style={{width: 20, height: 20, borderRadius: 50, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center'}}>
                            <Entypo name="cross" size={12} color="white" />
                        </TouchableOpacity>

                        
                    </View>

                    <View style={{marginRight: 30}}>
                        <Ionicons name="md-arrow-forward-circle-sharp" size={24} color="black" />
                    </View>
            </TouchableOpacity>
        )
    }

    const renderAllList =({item})=>{
        return(
            <TouchableOpacity onPress={()=> navigation.navigate('TopActivityActive', {orderDet: item})} style={{height: 80, width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                    
                    
                    <View style={{marginLeft: 30, width: 250}}>

                            <View style={{}}>
                                <Text style={{fontSize: 16, fontWeight: '800'}}>{item?.busName}</Text>
                            </View>
                        <View style={{flexDirection: 'row', height: 30}}>

                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <Entypo name='shopping-cart' size={10} color="black" />
                                <Text style={{marginLeft: 15}}>{item?.category}</Text>
                            </View>
                          
                            <View style={{flexDirection: 'row', marginLeft: 15, alignItems: 'center'}}>
                                {/* calendar */}
                                <View>
                                   <Entypo name='wallet' size={10} color="black" />
                                </View>

                                {/* date */}
                                <View style={{marginLeft: 10}}>
                                    <Text style={{fontSize: 13}}>R{Number(item?.balance).toFixed(2)}</Text>
                                </View>
                            </View>

                           

                        </View>
                        
                        <Text style={{fontSize: 13}}>{item?.website}</Text>

                         
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <TouchableOpacity onPress={()=> console.warn('cancelled')} style={{width: 20, height: 20, borderRadius: 50, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center'}}>
                            <Entypo name="cross" size={12} color="white" />
                        </TouchableOpacity>

                        
                    </View>

                    <View style={{marginRight: 30}}>
                        <Ionicons name="md-arrow-forward-circle-sharp" size={24} color="black" />
                    </View>
            </TouchableOpacity>
        )
    }

    const itemSeperator = ()=> {
        return(
            <View style={{height: 1, backgroundColor: '#d1d1d1'}} />
        )
    }

    let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    let shopUpdate;
    let shopUpdateDel;
    let shopUpdateCreate;

    function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onUpdatedShopRequest)).subscribe( {next: (daraa) => {
         setReqUpdt(daraa)
     }, })
     
     subsUpdateDel = API.graphql(graphqlOperation(onDeletedShopRequest)).subscribe( {next: (daraaa) => {
         setReqUpdt(daraaa)
     }, }) 

     subsUpdateCreate = API.graphql(graphqlOperation(onAddedShopRequest)).subscribe( {next: (daraaaaa) => {
         setReqUpdt(daraaaaa)
     }, }) 

     shopUpdateCreate = API.graphql(graphqlOperation(onShopAdded)).subscribe( {next: (daraa) => {
         setReqUpdt(daraa)
     }, })
     
     shopUpdateDel = API.graphql(graphqlOperation(onShopDeleted)).subscribe( {next: (daraaa) => {
         setReqUpdt(daraaa)
     }, }) 

     shopUpdate = API.graphql(graphqlOperation(onShopUpdated)).subscribe( {next: (daraaaaa) => {
         setReqUpdt(daraaaaa)
     }, }) 

    }


    useEffect(() => {
      fetchUsers();
      setUpSus(); 
      
      return() =>{
            subsUpdate.unsubscribe();
            subsUpdateDel.unsubscribe();
            subsUpdateCreate.unsubscribe();
            shopUpdate.unsubscribe();
            shopUpdateDel.unsubscribe();
            shopUpdateCreate.unsubscribe();
        };
    }, [ReqUpdt])
    

  return (
    <View style={{flex: 1, backgroundColor: 'white', flexDirection: 'row'}}>
        <View style={{width: '50%', borderRightWidth: 1, borderColor: '#d1d1d1'}}>
            <View style={{marginLeft: 25, marginTop: 25}}>
            <Text style={{fontSize: 18, fontWeight: '800'}}>Shop Requests</Text>
            </View>
              <View style={{marginTop: 40}}>
            <FlatList 
                showsVerticalScrollIndicator={true}
                scrollEnabled={true}
                data={reqList}
                keyExtractor={(item, index) => index.toString()}
                renderItem={renderList}
                style={{height: 400}}
                ItemSeparatorComponent={itemSeperator}
            />
      
        </View>
        </View>
        <View style={{width: '50%', borderRightWidth: 1, borderColor: '#d1d1d1'}}>
            <View style={{marginLeft: 25, marginTop: 25}}>
            <Text style={{fontSize: 18, fontWeight: '800'}}>All Shops</Text>
            </View>
              <View style={{marginTop: 40}}>
            <FlatList 
                showsVerticalScrollIndicator={true}
                scrollEnabled={true}
                data={shopList}
                keyExtractor={(item, index) => index.toString()}
                renderItem={renderAllList}
                style={{height: 400}}
                ItemSeparatorComponent={itemSeperator}
            />
      
        </View>
        </View>

      
      
    </View>
  )
}

export default StoreScreen